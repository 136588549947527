import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: '/Home'
  },
  {
    path: '/Home',
    meta:{
      title:'首页',
    },
    component: () => import('../views/Home')
  },
  {
    path: '/Order',
    meta:{
      title:'订单支付',
    },
    component: () => import('../views/Order.vue')
  },
  {
    path: '/Card',
    meta:{
      title:'电子卡',
    },
    component: () => import('../views/Card.vue')
  },
  {
    path: '/verification',
    meta:{
      title:'验证身份',
    },
    component: () => import('../views/verification.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  routes
})

router.beforeEach((to, from, next) => {
  /* 路由发生变化修改页面title */
  if (to.meta.title) {
    document.title = to.matched[0].meta.title
  }
  next()
})

export default router
